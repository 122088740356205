import { Card, Col, Form, Row } from "antd";
import { CardTitle } from "./CardTitle";
import { useMemo, useState } from "react";
import { Typography } from "../../../../../../design-systems";
import { EmploymentInformationForm } from "./forms";
import { useSelector } from "react-redux";
import moment from "moment";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllDivisions, getEmploymentStatuses } from "../../../../../../redux/actions";

export const EmploymentInformation = ({ getEmployeeData }) => {
  const [form] = Form.useForm();
  const [divisionList, setDivisionList] = useState([])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmploymentStatuses());
    dispatch(
      getAllDivisions((res) => {
        setDivisionList(res.data);
      }),
    );
  }, [dispatch])
  const [editEmploymentInformation, setEditEmploymentInformation] =
    useState(false);
  const { employeeDetails, employmentStatuses } = useSelector((state) => state.employees);

  const getEmploymentStatus = useCallback((searchValue) => {
    if (!searchValue) return
    const val = Object.entries(employmentStatuses).find(([key, value]) => value === searchValue);
    if (val?.length) {
      return val[0];
    }
    return ""
  }, [employmentStatuses])

  const getDivisonName = useMemo(() => {
    if (!employeeDetails?.divisionId?.value || !divisionList?.length) return "";
    const divName = divisionList.find(div => div?.division_id === employeeDetails?.divisionId?.value);
    return divName?.division_name || "";
  }, [employeeDetails, divisionList]);


  const setInitialFieldsValue = () => {
    const initialFieldsValue = {
      status: employeeDetails?.employmentStatus?.value,
      employment_termination_date: employeeDetails?.employmentTerminationDate?.value ? moment(employeeDetails?.employmentTerminationDate?.value) : null,
      division: employeeDetails?.division_name?.value,
    };
    form.setFieldsValue(initialFieldsValue);
  };

  const data = useMemo(
    () => [
      {
        label: "Employee ID",
        value: `${employeeDetails?.employeeID?.value ?? "-"}`,
      },
      {
        label: "Employment start date",
        value: `${employeeDetails?.employeeDateOfJoining?.value ? moment(employeeDetails?.employeeDateOfJoining?.value).format("MM/DD/YYYY") : "-"}`,
      },
      {
        label: "Employment status",
        value: `${getEmploymentStatus(employeeDetails?.employmentStatus?.value) ?? "-"}`,
      },
      {
        label: "Employment termination date",
        value: `${employeeDetails?.employmentTerminationDate?.value ? moment(employeeDetails?.employmentTerminationDate?.value).format("MM/DD/YYYY") : "-"}`,
      },
      {
        label: "Division",
        value: getDivisonName,
      },
    ],
    [employeeDetails, getEmploymentStatus, getDivisonName],
  );

  return (
    <Card
      title={
        <CardTitle
          title="Employment Information"
          onEdit={() => {
            setInitialFieldsValue()
            setEditEmploymentInformation(true)
          }}
        />
      }
      bordered
      className="emp-detail-card plan-information-wrapper"
    >
      <Row gutter={[4, 8]}>
        {data.map(({ label, value }) => {
          if ((label === "Division" && (value === "default" || !value))) {
            return null
          }
          return (
            <>
              <Col xs={12}>
                <Typography
                  variant="body-3"
                  label={label}
                  className="card-info-label"
                />
              </Col>
              <Col xs={12}>
                <Typography
                  variant="body-2"
                  label={value}
                  className="card-info-value"
                />
              </Col>
            </>
          )
        })}
      </Row>
      <EmploymentInformationForm
        form={form}
        getEmployeeData={getEmployeeData}
        visible={editEmploymentInformation}
        onCancel={() => setEditEmploymentInformation(false)}
        divisionList={divisionList}
        getDivisonName={getDivisonName}
      />
    </Card>
  );
};
